
/*------------------------------------
- COLOR primary
------------------------------------*/
.alert-primary {
    color: #19635c;
    background-color: #d0f3ef;
    border-color: #c0eeea;
}

.alert-primary hr {
    border-top-color: #ace9e3;
}

.alert-primary .alert-link {
    color: #0f3b36;
}

.badge-primary {
    color: #212529;
    background-color: #33c5b6;
}

/*SPECIAL FOR GRADIENT BACKGROUNDS*/
.gradient-right.bg-primary {
    background-image: linear-gradient(to right bottom, #33c5b6, #d0f3ef); /* #c2a637 */
}
.gradient-card.bg-primary {
    background-image: linear-gradient( 20deg, #33c5b6, #d0f3ef); /* #212B3A */
}
.gradient-hover.bg-primary { /* TODO not good, change for netflix hover effect https://www.chrisgeelhoed.com/might-not-need-javascript-netflix-style-hover-effects/ */
    background-image: linear-gradient( 340deg, #212B3A, #33c5b6); /* #34C1B4 */
}
.gradient-d-bottom.bg-primary { 
    background-image: linear-gradient(to top, #212B3A, #33c5b6); /* #34C1B4 */
}
.gradient-d-top.bg-primary { 
    background-image: linear-gradient(to bottom, #212B3A, #33c5b6); /* #34C1B4 */
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #212529;
    background-color: #289c90;
}

.bg-primary {
    background-color: #33c5b6 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #289c90 !important;
}

.border-primary {
    border-color: #33c5b6 !important;
}

.btn-primary {
    color: #212529;
    background-color: #33c5b6;
    border-color: #33c5b6;
}

.btn-primary:hover {
    color: #212529;
    background-color: #2ba89b;
    border-color: #289c90;
}

.btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 197, 182, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #33c5b6;
    border-color: #33c5b6;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #289c90;
    border-color: #259085;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 197, 182, 0.5);
}

.btn-outline-primary {
    color: #33c5b6;
    background-color: transparent;
    border-color: #33c5b6;
}

.btn-outline-primary:hover {
    color: #212529;
    background-color: #33c5b6;
    border-color: #33c5b6;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 197, 182, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #33c5b6;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #33c5b6;
    border-color: #33c5b6;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 197, 182, 0.5);
}

.list-group-item-primary {
    color: #19635c;
    background-color: #c0eeea;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #19635c;
    background-color: #ace9e3;
}

.list-group-item-primary.list-group-item-action.active {
    color: #212529;
    background-color: #19635c;
    border-color: #19635c;
}

.table-primary,
.table-primary > th,
.table-primary > td {
    background-color: #c0eeea;
}

.table-hover .table-primary:hover {
    background-color: #ace9e3;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #ace9e3;
}

.text-primary {
    color: #33c5b6 !important;
}

a.text-primary:hover, a.text-primary:focus {
    color: #289c90 !important;
}

/*------------------------------------
- COLOR info
------------------------------------*/
.alert-info {
    color: #000000;
    background-color: #758db1;
    border-color: #6882a9;
}

.alert-info hr {
    border-top-color: #5a759e;
}

.alert-info .alert-link {
    color: #000000;
}

.badge-info {
    color: #fff;
    background-color: #212b3a;
}

.badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    background-color: #0e1219;
}

.bg-info {
    background-color: #212b3a !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #0e1219 !important;
}

.border-info {
    border-color: #212b3a !important;
}

.btn-info {
    color: #fff;
    background-color: #212b3a;
    border-color: #212b3a;
}

.btn-info:hover {
    color: #fff;
    background-color: #141a23;
    border-color: #0e1219;
}

.btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #212b3a;
    border-color: #212b3a;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0e1219;
    border-color: #080b0f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-outline-info {
    color: #212b3a;
    background-color: transparent;
    border-color: #212b3a;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #212b3a;
    border-color: #212b3a;
}

.btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #212b3a;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #212b3a;
    border-color: #212b3a;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.list-group-item-info {
    color: #000000;
    background-color: #6882a9;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #000000;
    background-color: #5a759e;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
}

.table-info,
.table-info > th,
.table-info > td {
    background-color: #6882a9;
}

.table-hover .table-info:hover {
    background-color: #5a759e;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #5a759e;
}

.text-info {
    color: #212b3a !important;
}

a.text-info:hover, a.text-info:focus {
    color: #0e1219 !important;
}

/*------------------------------------
- COLOR success
------------------------------------*/
.alert-success {
    color: #235d38;
    background-color: #d7efe0;
    border-color: #c8ead4;
}

.alert-success hr {
    border-top-color: #b5e3c6;
}

.alert-success .alert-link {
    color: #153822;
}

.badge-success {
    color: #212529;
    background-color: #45b66f;
}

.badge-success[href]:hover, .badge-success[href]:focus {
    color: #212529;
    background-color: #369158;
}

.bg-success {
    background-color: #45b66f !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #369158 !important;
}

.border-success {
    border-color: #45b66f !important;
}

.btn-success {
    color: #212529;
    background-color: #45b66f;
    border-color: #45b66f;
}

.btn-success:hover {
    color: #212529;
    background-color: #3b9c5f;
    border-color: #369158;
}

.btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 111, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #45b66f;
    border-color: #45b66f;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #212529;
    background-color: #369158;
    border-color: #328551;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 111, 0.5);
}

.btn-outline-success {
    color: #45b66f;
    background-color: transparent;
    border-color: #45b66f;
}

.btn-outline-success:hover {
    color: #212529;
    background-color: #45b66f;
    border-color: #45b66f;
}

.btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 111, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #45b66f;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #45b66f;
    border-color: #45b66f;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 111, 0.5);
}

.list-group-item-success {
    color: #235d38;
    background-color: #c8ead4;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #235d38;
    background-color: #b5e3c6;
}

.list-group-item-success.list-group-item-action.active {
    color: #212529;
    background-color: #235d38;
    border-color: #235d38;
}

.table-success,
.table-success > th,
.table-success > td {
    background-color: #c8ead4;
}

.table-hover .table-success:hover {
    background-color: #b5e3c6;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #b5e3c6;
}

.text-success {
    color: #45b66f !important;
}

a.text-success:hover, a.text-success:focus {
    color: #369158 !important;
}

/*------------------------------------
- COLOR warning
------------------------------------*/
.alert-warning {
    color: #62541b;
    background-color: #f2ecd2;
    border-color: #ede5c2;
}

.alert-warning hr {
    border-top-color: #e8dcae;
}

.alert-warning .alert-link {
    color: #3a3210;
}

.badge-warning {
    color: #212529;
    background-color: #c2a637;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    background-color: #9a832b;
}

.bg-warning {
    background-color: #c2a637 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #9a832b !important;
}

.border-warning {
    border-color: #c2a637 !important;
}

.btn-warning {
    color: #212529;
    background-color: #c2a637;
    border-color: #c2a637;
}

.btn-warning:hover {
    color: #212529;
    background-color: #a68e2f;
    border-color: #9a832b;
}

.btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 166, 55, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #c2a637;
    border-color: #c2a637;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #9a832b;
    border-color: #8e7928;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 166, 55, 0.5);
}

.btn-outline-warning {
    color: #c2a637;
    background-color: transparent;
    border-color: #c2a637;
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #c2a637;
    border-color: #c2a637;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 166, 55, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #c2a637;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #c2a637;
    border-color: #c2a637;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 166, 55, 0.5);
}

.list-group-item-warning {
    color: #62541b;
    background-color: #ede5c2;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #62541b;
    background-color: #e8dcae;
}

.list-group-item-warning.list-group-item-action.active {
    color: #212529;
    background-color: #62541b;
    border-color: #62541b;
}

.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: #ede5c2;
}

.table-hover .table-warning:hover {
    background-color: #e8dcae;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #e8dcae;
}

.text-warning {
    color: #c2a637 !important;
}

a.text-warning:hover, a.text-warning:focus {
    color: #9a832b !important;
}

/*------------------------------------
- COLOR danger
------------------------------------*/
.alert-danger {
    color: #a61309;
    background-color: #fef7f7;
    border-color: #fde5e4;
}

.alert-danger hr {
    border-top-color: #fccfcb;
}

.alert-danger .alert-link {
    color: #760e06;
}

.badge-danger {
    color: #fff;
    background-color: #f44336;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    background-color: #ea1b0c;
}

.bg-danger {
    background-color: #f44336 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #ea1b0c !important;
}

.border-danger {
    border-color: #f44336 !important;
}

.btn-danger {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}

.btn-danger:hover {
    color: #fff;
    background-color: #f22314;
    border-color: #ea1b0c;
}

.btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1b0c;
    border-color: #db1a0c;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-outline-danger {
    color: #f44336;
    background-color: transparent;
    border-color: #f44336;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.list-group-item-danger {
    color: #a61309;
    background-color: #fde5e4;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #a61309;
    background-color: #fccfcb;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #a61309;
    border-color: #a61309;
}

.table-danger,
.table-danger > th,
.table-danger > td {
    background-color: #fde5e4;
}

.table-hover .table-danger:hover {
    background-color: #fccfcb;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #fccfcb;
}

.text-danger {
    color: #f44336 !important;
}

a.text-danger:hover, a.text-danger:focus {
    color: #ea1b0c !important;
}
