/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Montserrat&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@400;700&display=swap');

html, body {
  overflow-x: hidden; /* cuts out mysterious right margin on xs screen sizes */
  background-color: #ffffff;
  margin: 0;
  padding-top: 29px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Montserrat', 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Open Sans'
}

h1 {
  font-weight: 700;
}

.btn {
  font-family: 'Open Sans';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
