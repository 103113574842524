@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@400;700&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Montserrat&display=swap'); */

html, body {
  overflow-x: hidden; /* cuts out mysterious right margin on xs screen sizes */
  background-color: #ffffff;
  margin: 0;
  padding-top: 29px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Montserrat', 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Open Sans'
}

h1 {
  font-weight: 700;
}

.btn {
  font-family: 'Open Sans';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
  #F0F5F1
  #92A87F
  #34C1B4
  #85726B
  #233244

  EAF1F3 - lishade
*//*
  #c3efeb lishade
  #879180 liaccent
  #34C1B4 main
  #766F6A dkaccent
  #212B3A dkshade
  #33c5b6 primary
  #4faba0 secondary
  #212b3a info
  #45b66f success
  #c2a637 warning
  #f44336 danger
*//*
  #F2F4EF
  #809868
  #34C1B4
  #8A556E
  #396169
  #46b46f
  #c3a337
  #f44336
*//*
  #EBE7E1
  #76BFCA
  #278F85
  #838C5C
  #1F2928
*/
/*
*/

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  z-index:2;
  color:#ffffff;
  background-color:#33c5b6;
  border-color:#33c5b6;
}

/* Flos Farbenpracht */
.text-blue {
  color: #050038;
}
.text-lightmiuvi {
  color: #8BDDD4;
}
.text-pink {
  color: #ff7bac;
}
.text-yellow {
  color: #FFD02F;
}
.text-lightyellow {
  color: #FFEAA8;
}
.text-lightpink {
  color: #FF9DC1;
}
.bg-yellow {
  background-color: #FFD02F;
}
.bg-lightpink {
  background-color: #FF9DC1;
}
.bg-lightmiuvi {
  background-color: #8BDDD4;
}

.bg-lightyellow {
  background-color: #FFEAA8;
}
.bg-blue {
  background-color: #050038;
}
.bg-pink {
  background-color: #ff7bac;
}
.border-yellow {
  border-color: #FFD02F;
}
.border-pink {
  border-color: #ff7bac;
}
.border-blue {
  border-color: #050038;
}
/* aus */


.text-coral {
  color: #f8ff8b;
}

.text-primary.hovered {
  color: #4faba0;
}

.bg-orange {
  background-color: #f18837;
}

.bg-purple {
  background-color: #7533b5;
}

.border-orange {
  border-color: #f18837;
}

.border-purple {
  border-color: #7533b5;
}

.bg-red {
  background-color: #F22654;
}

.text-red {
  color: #F22654;
}

.border-red {
  border-color: #F22654;
}

.border-yellow {
  border-color: #ffd60b;
}


.hoverable:hover {
  border-color: #4faba0;
}

/* TODO change in bootstrap override for -lishade-, change in app.js */
.bg-col-mi {
  background-color: #c3efeb;
}

.border-dashed {
  border-style: dashed;
  border-width: medium;
}

.form-control:focus {
  border-color: #766F6A;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(118, 111, 106, 0.6);
}

.rounded-xxl {
  border-radius: 2rem;
}

/* TODO make colors changeable */
.border-corners {position:relative;}
.border-corners:before, .border-corners:after, .border-corners>:first-child:before, .border-corners>:first-child:after {
    position:absolute;
    width:5rem; height: 5rem;
    border-color:#33c5b6;
    border-style:solid;
    content: ' ';
}
.border-corners:before {top:0;left:0;border-width: 5px 0 0 5px}
.border-corners:after {top:0;right:0;border-width: 5px 5px 0 0}
.border-corners>:first-child:before {bottom:0;right:0;border-width: 0 5px 5px 0}
.border-corners>:first-child:after {bottom:0;left:0;border-width: 0 0 5px 5px}

.border-corners-s {position:relative; width:10rem; height: 7.5rem;}
.border-corners-s:before, .border-corners-s:after, .border-corners-s>:first-child:before, .border-corners-s>:first-child:after {
    position:absolute;
    width:1.5rem; height: 1.5rem;
    border-color:#33c5b6;
    border-style:solid;
    content: ' ';
}
.border-corners-s:before {top:0;left:0;border-width: 1px 0 0 1px}
.border-corners-s:after {top:0;right:0;border-width: 1px 1px 0 0}
.border-corners-s>:first-child:before {bottom:0;right:0;border-width: 0 1px 1px 0}
.border-corners-s>:first-child:after {bottom:0;left:0;border-width: 0 0 1px 1px}

@keyframes blink {
  50% {
    opacity: 0.0;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0.0;
  }
}
.blink {
  animation: blink 2s step-start 0s infinite;
  -webkit-animation: blink 2s step-start 0s infinite;
}


.embed-responsive-9by16 {
  padding-bottom: 177.78%;
}


@media (max-width: 575.8px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  h5 {
    font-size: 1rem;
  }
}

/* Number of Cards by Row based on Viewport */
@media (min-width: 576px) {
  .card-deck .card.card-wider {
    min-width: 50.1%; /* 1 Column */
    max-width: 80%;
  }
  .card-deck .card {
      /* min-width: 50.1%; /* 1 Column *//*
      max-width: 80%;
      margin-bottom: 12px; */
      min-width: 33.4%;  /* 2 Columns */
      max-width: 50%;
  }
}
@media (min-width: 768px) {
  .card-deck .card.card-wider {
    min-width: 33.4%;  /* 2 Columns */
    max-width: 50%;
  }
  .card-deck .card {
      /* min-width: 33.4%;  2 Columns
      max-width: 50%; */
      min-width: 25.1%;  /* 3 Columns */
      max-width: 33.3%;
  }
}
@media (min-width: 992px) {
  .card-deck .card {
      min-width: 25.1%;  /* 3 Columns */
      max-width: 33.3%;
  }
}
@media (min-width: 1200px) {
  .card-deck .card.card-wider {
    min-width: 0;
    max-width: 100%;
  }
  .card-deck .card {
      min-width: 0;
      max-width: 100%;
  }
}


/* add bg-video class to container containing video tag */
/* put everything else into child container of parent container */
.bg-video {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.bg-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.bg-video .container {
  position: relative;
  z-index: 2;
}
@media (pointer: coarse) and (hover: none) {
  /* .bg-video video{
    background: url("/businessman_fb.png") black no-repeat center center scroll;
  } */
  .bg-video video{
    display: none;
  }
}


.dnd {
  height: 10rem;
}

.dnd-b Input[type=file] { /*TODO get to work*/
    display: none;
  }

.colorPicker {
  height: 10rem;
  width: 10rem;
}
.colorPicker input[type=color] { /*broken*/
  display: none;
}

#modalCountdown h1{
  color: white;
  font-size: 185px;
  font-family: 'Montserrat', sans-serif;
}

#countdown-text{
  position:relative;
    color: rgb(230, 200, 98);
    z-index:1;
    top: 0;
    text-align: center; 
}

.noUi-horizontal .noUi-handle {
}

.noUi-handle[data-handle="0"] {
  height: 26px !important;
  top: -5px !important;
  background-color: #212B3A;
  z-index: 5; /*why not working?*/
  border-radius: 10px 0 0 10px;
  width: 10px !important;
  right: 0 !important;
}

.noUi-handle[data-handle="1"] {
  height: 26px !important;
  top: -5px !important;
  background-color: #fff;
  z-index: -5;
  border-radius:  10px 10px 10px 10px;
  width: 4px !important;
  right: -2px !important;
}

.noUi-handle[data-handle="2"] {
  height: 26px !important;
  top: -5px !important;
  background-color: #212B3A;
  z-index: 5; /*why not working?*/
  border-radius: 0 10px 10px 0;
  width: 10px !important;
  right: -10px !important;
}

.noUi-handle:before, .noUi-handle:after {
  display: none !important;
} 

#c-p-bn, #s-all-bn.c-bn {
  background-color: #33c5b6 !important;
}

/* .theme_turquoise{
  --cc-bg: #161a1c;
  --cc-text: #d8e5ea;
  --cc-btn-primary-bg: #60fed2;
  --cc-btn-primary-text: #000;
  --cc-btn-primary-hover-bg: #4dd4ae;
  --cc-btn-secondary-bg: #242c31;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #2b353c;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #343e45;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);
  --cc-cookie-category-block-bg: #1e2428;
  --cc-cookie-category-block-bg-hover: #242c31;
  --cc-section-border: #222a30;
  --cc-block-text: #bac9cf;
  --cc-cookie-table-border: #2b3035;
  --cc-overlay-bg: rgba(4, 6, 8, .85);
  --cc-webkit-scrollbar-bg: #2c343a;
  --cc-webkit-scrollbar-bg-hover: #384148;
}

.theme_turquoise .cc_div a{
  color: var(--cc-btn-primary-bg);
}

.theme_turquoise #cc_div #s-hdr{
  border-bottom: none;
}

.theme_turquoise .cc_div #c-txt{
  color: #b0b8c6!important;
} */

/* TODO make obsolete */
  .radio-music {
    display: block;
    height: 5rem;
    margin: 1rem auto;
    width: 5rem;
  }

/*------------------------------------
- COLOR primary
------------------------------------*/
.alert-primary {
    color: #19635c;
    background-color: #d0f3ef;
    border-color: #c0eeea;
}

.alert-primary hr {
    border-top-color: #ace9e3;
}

.alert-primary .alert-link {
    color: #0f3b36;
}

.badge-primary {
    color: #212529;
    background-color: #33c5b6;
}

/*SPECIAL FOR GRADIENT BACKGROUNDS*/
.gradient-right.bg-primary {
    background-image: linear-gradient(to right bottom, #33c5b6, #d0f3ef); /* #c2a637 */
}
.gradient-card.bg-primary {
    background-image: linear-gradient( 20deg, #33c5b6, #d0f3ef); /* #212B3A */
}
.gradient-hover.bg-primary { /* TODO not good, change for netflix hover effect https://www.chrisgeelhoed.com/might-not-need-javascript-netflix-style-hover-effects/ */
    background-image: linear-gradient( 340deg, #212B3A, #33c5b6); /* #34C1B4 */
}
.gradient-d-bottom.bg-primary { 
    background-image: linear-gradient(to top, #212B3A, #33c5b6); /* #34C1B4 */
}
.gradient-d-top.bg-primary { 
    background-image: linear-gradient(to bottom, #212B3A, #33c5b6); /* #34C1B4 */
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #212529;
    background-color: #289c90;
}

.bg-primary {
    background-color: #33c5b6 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #289c90 !important;
}

.border-primary {
    border-color: #33c5b6 !important;
}

.btn-primary {
    color: #212529;
    background-color: #33c5b6;
    border-color: #33c5b6;
}

.btn-primary:hover {
    color: #212529;
    background-color: #2ba89b;
    border-color: #289c90;
}

.btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 197, 182, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #33c5b6;
    border-color: #33c5b6;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #289c90;
    border-color: #259085;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 197, 182, 0.5);
}

.btn-outline-primary {
    color: #33c5b6;
    background-color: transparent;
    border-color: #33c5b6;
}

.btn-outline-primary:hover {
    color: #212529;
    background-color: #33c5b6;
    border-color: #33c5b6;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 197, 182, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #33c5b6;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #33c5b6;
    border-color: #33c5b6;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 197, 182, 0.5);
}

.list-group-item-primary {
    color: #19635c;
    background-color: #c0eeea;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #19635c;
    background-color: #ace9e3;
}

.list-group-item-primary.list-group-item-action.active {
    color: #212529;
    background-color: #19635c;
    border-color: #19635c;
}

.table-primary,
.table-primary > th,
.table-primary > td {
    background-color: #c0eeea;
}

.table-hover .table-primary:hover {
    background-color: #ace9e3;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #ace9e3;
}

.text-primary {
    color: #33c5b6 !important;
}

a.text-primary:hover, a.text-primary:focus {
    color: #289c90 !important;
}

/*------------------------------------
- COLOR info
------------------------------------*/
.alert-info {
    color: #000000;
    background-color: #758db1;
    border-color: #6882a9;
}

.alert-info hr {
    border-top-color: #5a759e;
}

.alert-info .alert-link {
    color: #000000;
}

.badge-info {
    color: #fff;
    background-color: #212b3a;
}

.badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    background-color: #0e1219;
}

.bg-info {
    background-color: #212b3a !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #0e1219 !important;
}

.border-info {
    border-color: #212b3a !important;
}

.btn-info {
    color: #fff;
    background-color: #212b3a;
    border-color: #212b3a;
}

.btn-info:hover {
    color: #fff;
    background-color: #141a23;
    border-color: #0e1219;
}

.btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #212b3a;
    border-color: #212b3a;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0e1219;
    border-color: #080b0f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-outline-info {
    color: #212b3a;
    background-color: transparent;
    border-color: #212b3a;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #212b3a;
    border-color: #212b3a;
}

.btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #212b3a;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #212b3a;
    border-color: #212b3a;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.list-group-item-info {
    color: #000000;
    background-color: #6882a9;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #000000;
    background-color: #5a759e;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
}

.table-info,
.table-info > th,
.table-info > td {
    background-color: #6882a9;
}

.table-hover .table-info:hover {
    background-color: #5a759e;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #5a759e;
}

.text-info {
    color: #212b3a !important;
}

a.text-info:hover, a.text-info:focus {
    color: #0e1219 !important;
}

/*------------------------------------
- COLOR success
------------------------------------*/
.alert-success {
    color: #235d38;
    background-color: #d7efe0;
    border-color: #c8ead4;
}

.alert-success hr {
    border-top-color: #b5e3c6;
}

.alert-success .alert-link {
    color: #153822;
}

.badge-success {
    color: #212529;
    background-color: #45b66f;
}

.badge-success[href]:hover, .badge-success[href]:focus {
    color: #212529;
    background-color: #369158;
}

.bg-success {
    background-color: #45b66f !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #369158 !important;
}

.border-success {
    border-color: #45b66f !important;
}

.btn-success {
    color: #212529;
    background-color: #45b66f;
    border-color: #45b66f;
}

.btn-success:hover {
    color: #212529;
    background-color: #3b9c5f;
    border-color: #369158;
}

.btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 111, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #45b66f;
    border-color: #45b66f;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #212529;
    background-color: #369158;
    border-color: #328551;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 111, 0.5);
}

.btn-outline-success {
    color: #45b66f;
    background-color: transparent;
    border-color: #45b66f;
}

.btn-outline-success:hover {
    color: #212529;
    background-color: #45b66f;
    border-color: #45b66f;
}

.btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 111, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #45b66f;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #45b66f;
    border-color: #45b66f;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 111, 0.5);
}

.list-group-item-success {
    color: #235d38;
    background-color: #c8ead4;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #235d38;
    background-color: #b5e3c6;
}

.list-group-item-success.list-group-item-action.active {
    color: #212529;
    background-color: #235d38;
    border-color: #235d38;
}

.table-success,
.table-success > th,
.table-success > td {
    background-color: #c8ead4;
}

.table-hover .table-success:hover {
    background-color: #b5e3c6;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #b5e3c6;
}

.text-success {
    color: #45b66f !important;
}

a.text-success:hover, a.text-success:focus {
    color: #369158 !important;
}

/*------------------------------------
- COLOR warning
------------------------------------*/
.alert-warning {
    color: #62541b;
    background-color: #f2ecd2;
    border-color: #ede5c2;
}

.alert-warning hr {
    border-top-color: #e8dcae;
}

.alert-warning .alert-link {
    color: #3a3210;
}

.badge-warning {
    color: #212529;
    background-color: #c2a637;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    background-color: #9a832b;
}

.bg-warning {
    background-color: #c2a637 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #9a832b !important;
}

.border-warning {
    border-color: #c2a637 !important;
}

.btn-warning {
    color: #212529;
    background-color: #c2a637;
    border-color: #c2a637;
}

.btn-warning:hover {
    color: #212529;
    background-color: #a68e2f;
    border-color: #9a832b;
}

.btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 166, 55, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #c2a637;
    border-color: #c2a637;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #9a832b;
    border-color: #8e7928;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 166, 55, 0.5);
}

.btn-outline-warning {
    color: #c2a637;
    background-color: transparent;
    border-color: #c2a637;
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #c2a637;
    border-color: #c2a637;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 166, 55, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #c2a637;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #c2a637;
    border-color: #c2a637;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 166, 55, 0.5);
}

.list-group-item-warning {
    color: #62541b;
    background-color: #ede5c2;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #62541b;
    background-color: #e8dcae;
}

.list-group-item-warning.list-group-item-action.active {
    color: #212529;
    background-color: #62541b;
    border-color: #62541b;
}

.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: #ede5c2;
}

.table-hover .table-warning:hover {
    background-color: #e8dcae;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #e8dcae;
}

.text-warning {
    color: #c2a637 !important;
}

a.text-warning:hover, a.text-warning:focus {
    color: #9a832b !important;
}

/*------------------------------------
- COLOR danger
------------------------------------*/
.alert-danger {
    color: #a61309;
    background-color: #fef7f7;
    border-color: #fde5e4;
}

.alert-danger hr {
    border-top-color: #fccfcb;
}

.alert-danger .alert-link {
    color: #760e06;
}

.badge-danger {
    color: #fff;
    background-color: #f44336;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    background-color: #ea1b0c;
}

.bg-danger {
    background-color: #f44336 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #ea1b0c !important;
}

.border-danger {
    border-color: #f44336 !important;
}

.btn-danger {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}

.btn-danger:hover {
    color: #fff;
    background-color: #f22314;
    border-color: #ea1b0c;
}

.btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1b0c;
    border-color: #db1a0c;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-outline-danger {
    color: #f44336;
    background-color: transparent;
    border-color: #f44336;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.list-group-item-danger {
    color: #a61309;
    background-color: #fde5e4;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #a61309;
    background-color: #fccfcb;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #a61309;
    border-color: #a61309;
}

.table-danger,
.table-danger > th,
.table-danger > td {
    background-color: #fde5e4;
}

.table-hover .table-danger:hover {
    background-color: #fccfcb;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #fccfcb;
}

.text-danger {
    color: #f44336 !important;
}

a.text-danger:hover, a.text-danger:focus {
    color: #ea1b0c !important;
}

/*------------------------------------
lishade, liaccent, miuvi, dkaccent, dkshade
------------------------------------*/

/*------------------------------------
- COLOR lishade
------------------------------------*/
.alert-lishade {
    color: #9cbdc6;
    background-color: #16b14713d;
    border-color: #15e13e135;
}

.alert-lishade hr {
    border-top-color: #14e13412c;
}

.alert-lishade .alert-link {
    color: #7ba7b3;
}

.badge-lishade {
    color: #212529;
    background-color: #EAF1F3;
}

.badge-lishade[href]:hover, .badge-lishade[href]:focus {
    color: #212529;
    background-color: #c9dbe0;
}

.bg-lishade {
    background-color: #EAF1F3 !important;
}

a.bg-lishade:hover, a.bg-lishade:focus,
button.bg-lishade:hover,
button.bg-lishade:focus {
    background-color: #c9dbe0 !important;
}

.border-lishade {
    border-color: #EAF1F3 !important;
}

.btn-lishade {
    color: #212529;
    background-color: #EAF1F3;
    border-color: #EAF1F3;
}

.btn-lishade:hover {
    color: #212529;
    background-color: #d3e1e6;
    border-color: #c9dbe0;
}

.btn-lishade:focus, .btn-lishade.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 241, 243, 0.5);
}

.btn-lishade.disabled, .btn-lishade:disabled {
    color: #212529;
    background-color: #EAF1F3;
    border-color: #EAF1F3;
}

.btn-lishade:not(:disabled):not(.disabled):active, .btn-lishade:not(:disabled):not(.disabled).active, .show > .btn-lishade.dropdown-toggle {
    color: #212529;
    background-color: #c9dbe0;
    border-color: #bfd4da;
}

.btn-lishade:not(:disabled):not(.disabled):active:focus, .btn-lishade:not(:disabled):not(.disabled).active:focus, .show > .btn-lishade.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 241, 243, 0.5);
}

.btn-outline-lishade {
    color: #EAF1F3;
    background-color: transparent;
    border-color: #EAF1F3;
}

.btn-outline-lishade:hover {
    color: #212529;
    background-color: #EAF1F3;
    border-color: #EAF1F3;
}

.btn-outline-lishade:focus, .btn-outline-lishade.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 241, 243, 0.5);
}

.btn-outline-lishade.disabled, .btn-outline-lishade:disabled {
    color: #EAF1F3;
    background-color: transparent;
}

.btn-outline-lishade:not(:disabled):not(.disabled):active, .btn-outline-lishade:not(:disabled):not(.disabled).active, .show > .btn-outline-lishade.dropdown-toggle {
    color: #212529;
    background-color: #EAF1F3;
    border-color: #EAF1F3;
}

.btn-outline-lishade:not(:disabled):not(.disabled):active:focus, .btn-outline-lishade:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-lishade.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 241, 243, 0.5);
}

.list-group-item-lishade {
    color: #9cbdc6;
    background-color: #15e13e135;
}

.list-group-item-lishade.list-group-item-action:hover, .list-group-item-lishade.list-group-item-action:focus {
    color: #9cbdc6;
    background-color: #14e13412c;
}

.list-group-item-lishade.list-group-item-action.active {
    color: #212529;
    background-color: #9cbdc6;
    border-color: #9cbdc6;
}

.table-lishade,
.table-lishade > th,
.table-lishade > td {
    background-color: #15e13e135;
}

.table-hover .table-lishade:hover {
    background-color: #14e13412c;
}

.table-hover .table-lishade:hover > td,
.table-hover .table-lishade:hover > th {
    background-color: #14e13412c;
}

.text-lishade {
    color: #EAF1F3 !important;
}

a.text-lishade:hover, a.text-lishade:focus {
    color: #c9dbe0 !important;
}

/*------------------------------------
- COLOR liaccent
------------------------------------*/
.alert-liaccent {
    color: #4a5045;
    background-color: #eeefed;
    border-color: #e3e6e2;
}

.alert-liaccent hr {
    border-top-color: #d7dad4;
}

.alert-liaccent .alert-link {
    color: #31352e;
}

.badge-liaccent {
    color: #212529;
    background-color: #879180;
}

.badge-liaccent[href]:hover, .badge-liaccent[href]:focus {
    color: #212529;
    background-color: #6d7667;
}

.bg-liaccent {
    background-color: #879180 !important;
}

a.bg-liaccent:hover, a.bg-liaccent:focus,
button.bg-liaccent:hover,
button.bg-liaccent:focus {
    background-color: #6d7667 !important;
}

.border-liaccent {
    border-color: #879180 !important;
}

.btn-liaccent {
    color: #212529;
    background-color: #879180;
    border-color: #879180;
}

.btn-liaccent:hover {
    color: #212529;
    background-color: #757f6e;
    border-color: #6d7667;
}

.btn-liaccent:focus, .btn-liaccent.focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 145, 128, 0.5);
}

.btn-liaccent.disabled, .btn-liaccent:disabled {
    color: #212529;
    background-color: #879180;
    border-color: #879180;
}

.btn-liaccent:not(:disabled):not(.disabled):active, .btn-liaccent:not(:disabled):not(.disabled).active, .show > .btn-liaccent.dropdown-toggle {
    color: #212529;
    background-color: #6d7667;
    border-color: #666e5f;
}

.btn-liaccent:not(:disabled):not(.disabled):active:focus, .btn-liaccent:not(:disabled):not(.disabled).active:focus, .show > .btn-liaccent.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 145, 128, 0.5);
}

.btn-outline-liaccent {
    color: #879180;
    background-color: transparent;
    border-color: #879180;
}

.btn-outline-liaccent:hover {
    color: #212529;
    background-color: #879180;
    border-color: #879180;
}

.btn-outline-liaccent:focus, .btn-outline-liaccent.focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 145, 128, 0.5);
}

.btn-outline-liaccent.disabled, .btn-outline-liaccent:disabled {
    color: #879180;
    background-color: transparent;
}

.btn-outline-liaccent:not(:disabled):not(.disabled):active, .btn-outline-liaccent:not(:disabled):not(.disabled).active, .show > .btn-outline-liaccent.dropdown-toggle {
    color: #212529;
    background-color: #879180;
    border-color: #879180;
}

.btn-outline-liaccent:not(:disabled):not(.disabled):active:focus, .btn-outline-liaccent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-liaccent.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 145, 128, 0.5);
}

.list-group-item-liaccent {
    color: #4a5045;
    background-color: #e3e6e2;
}

.list-group-item-liaccent.list-group-item-action:hover, .list-group-item-liaccent.list-group-item-action:focus {
    color: #4a5045;
    background-color: #d7dad4;
}

.list-group-item-liaccent.list-group-item-action.active {
    color: #212529;
    background-color: #4a5045;
    border-color: #4a5045;
}

.table-liaccent,
.table-liaccent > th,
.table-liaccent > td {
    background-color: #e3e6e2;
}

.table-hover .table-liaccent:hover {
    background-color: #d7dad4;
}

.table-hover .table-liaccent:hover > td,
.table-hover .table-liaccent:hover > th {
    background-color: #d7dad4;
}

.text-liaccent {
    color: #879180 !important;
}

a.text-liaccent:hover, a.text-liaccent:focus {
    color: #6d7667 !important;
}

/*------------------------------------
- COLOR miuvi
------------------------------------*/
.alert-miuvi {
    color: #1a605a;
    background-color: #cef2ee;
    border-color: #beede9;
}

.alert-miuvi hr {
    border-top-color: #aae8e2;
}

.alert-miuvi .alert-link {
    color: #0f3834;
}

.badge-miuvi {
    color: #212529;
    background-color: #34C1B4;
}

.badge-miuvi[href]:hover, .badge-miuvi[href]:focus {
    color: #212529;
    background-color: #29988e;
}

.bg-miuvi {
    background-color: #34C1B4 !important;
}

a.bg-miuvi:hover, a.bg-miuvi:focus,
button.bg-miuvi:hover,
button.bg-miuvi:focus {
    background-color: #29988e !important;
}

.border-miuvi {
    border-color: #34C1B4 !important;
}

.btn-miuvi {
    color: #212529;
    background-color: #34C1B4;
    border-color: #34C1B4;
}

.btn-miuvi:hover {
    color: #212529;
    background-color: #2ca499;
    border-color: #29988e;
}

.btn-miuvi:focus, .btn-miuvi.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 193, 180, 0.5);
}

.btn-miuvi.disabled, .btn-miuvi:disabled {
    color: #212529;
    background-color: #34C1B4;
    border-color: #34C1B4;
}

.btn-miuvi:not(:disabled):not(.disabled):active, .btn-miuvi:not(:disabled):not(.disabled).active, .show > .btn-miuvi.dropdown-toggle {
    color: #212529;
    background-color: #29988e;
    border-color: #258c83;
}

.btn-miuvi:not(:disabled):not(.disabled):active:focus, .btn-miuvi:not(:disabled):not(.disabled).active:focus, .show > .btn-miuvi.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 193, 180, 0.5);
}

.btn-outline-miuvi {
    color: #34C1B4;
    background-color: transparent;
    border-color: #34C1B4;
}

.btn-outline-miuvi:hover {
    color: #212529;
    background-color: #34C1B4;
    border-color: #34C1B4;
}

.btn-outline-miuvi:focus, .btn-outline-miuvi.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 193, 180, 0.5);
}

.btn-outline-miuvi.disabled, .btn-outline-miuvi:disabled {
    color: #34C1B4;
    background-color: transparent;
}

.btn-outline-miuvi:not(:disabled):not(.disabled):active, .btn-outline-miuvi:not(:disabled):not(.disabled).active, .show > .btn-outline-miuvi.dropdown-toggle {
    color: #212529;
    background-color: #34C1B4;
    border-color: #34C1B4;
}

.btn-outline-miuvi:not(:disabled):not(.disabled):active:focus, .btn-outline-miuvi:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-miuvi.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 193, 180, 0.5);
}

.list-group-item-miuvi {
    color: #1a605a;
    background-color: #beede9;
}

.list-group-item-miuvi.list-group-item-action:hover, .list-group-item-miuvi.list-group-item-action:focus {
    color: #1a605a;
    background-color: #aae8e2;
}

.list-group-item-miuvi.list-group-item-action.active {
    color: #212529;
    background-color: #1a605a;
    border-color: #1a605a;
}

.table-miuvi,
.table-miuvi > th,
.table-miuvi > td {
    background-color: #beede9;
}

.table-hover .table-miuvi:hover {
    background-color: #aae8e2;
}

.table-hover .table-miuvi:hover > td,
.table-hover .table-miuvi:hover > th {
    background-color: #aae8e2;
}

.text-miuvi {
    color: #34C1B4 !important;
}

a.text-miuvi:hover, a.text-miuvi:focus {
    color: #29988e !important;
}

/*------------------------------------
- COLOR dkaccent
------------------------------------*/
.alert-dkaccent {
    color: #353230;
    background-color: #d8d5d3;
    border-color: #cecbc9;
}

.alert-dkaccent hr {
    border-top-color: #c2bebb;
}

.alert-dkaccent .alert-link {
    color: #1a1917;
}

.badge-dkaccent {
    color: #fff;
    background-color: #766F6A;
}

.badge-dkaccent[href]:hover, .badge-dkaccent[href]:focus {
    color: #fff;
    background-color: #5b5551;
}

.bg-dkaccent {
    background-color: #766F6A !important;
}

a.bg-dkaccent:hover, a.bg-dkaccent:focus,
button.bg-dkaccent:hover,
button.bg-dkaccent:focus {
    background-color: #5b5551 !important;
}

.border-dkaccent {
    border-color: #766F6A !important;
}

.btn-dkaccent {
    color: #fff;
    background-color: #766F6A;
    border-color: #766F6A;
}

.btn-dkaccent:hover {
    color: #fff;
    background-color: #635d59;
    border-color: #5b5551;
}

.btn-dkaccent:focus, .btn-dkaccent.focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 111, 106, 0.5);
}

.btn-dkaccent.disabled, .btn-dkaccent:disabled {
    color: #fff;
    background-color: #766F6A;
    border-color: #766F6A;
}

.btn-dkaccent:not(:disabled):not(.disabled):active, .btn-dkaccent:not(:disabled):not(.disabled).active, .show > .btn-dkaccent.dropdown-toggle {
    color: #fff;
    background-color: #5b5551;
    border-color: #534e4a;
}

.btn-dkaccent:not(:disabled):not(.disabled):active:focus, .btn-dkaccent:not(:disabled):not(.disabled).active:focus, .show > .btn-dkaccent.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 111, 106, 0.5);
}

.btn-outline-dkaccent {
    color: #766F6A;
    background-color: transparent;
    border-color: #766F6A;
}

.btn-outline-dkaccent:hover {
    color: #fff;
    background-color: #766F6A;
    border-color: #766F6A;
}

.btn-outline-dkaccent:focus, .btn-outline-dkaccent.focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 111, 106, 0.5);
}

.btn-outline-dkaccent.disabled, .btn-outline-dkaccent:disabled {
    color: #766F6A;
    background-color: transparent;
}

.btn-outline-dkaccent:not(:disabled):not(.disabled):active, .btn-outline-dkaccent:not(:disabled):not(.disabled).active, .show > .btn-outline-dkaccent.dropdown-toggle {
    color: #fff;
    background-color: #766F6A;
    border-color: #766F6A;
}

.btn-outline-dkaccent:not(:disabled):not(.disabled):active:focus, .btn-outline-dkaccent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dkaccent.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 111, 106, 0.5);
}

.list-group-item-dkaccent {
    color: #353230;
    background-color: #cecbc9;
}

.list-group-item-dkaccent.list-group-item-action:hover, .list-group-item-dkaccent.list-group-item-action:focus {
    color: #353230;
    background-color: #c2bebb;
}

.list-group-item-dkaccent.list-group-item-action.active {
    color: #fff;
    background-color: #353230;
    border-color: #353230;
}

.table-dkaccent,
.table-dkaccent > th,
.table-dkaccent > td {
    background-color: #cecbc9;
}

.table-hover .table-dkaccent:hover {
    background-color: #c2bebb;
}

.table-hover .table-dkaccent:hover > td,
.table-hover .table-dkaccent:hover > th {
    background-color: #c2bebb;
}

.text-dkaccent {
    color: #766F6A !important;
}

a.text-dkaccent:hover, a.text-dkaccent:focus {
    color: #5b5551 !important;
}

/*------------------------------------
- COLOR dkshade
------------------------------------*/
.alert-dkshade {
    color: #000000;
    background-color: #758db1;
    border-color: #6882a9;
}

.alert-dkshade hr {
    border-top-color: #5a759e;
}

.alert-dkshade .alert-link {
    color: #000000;
}

.badge-dkshade {
    color: #fff;
    background-color: #212B3A;
}

.badge-dkshade[href]:hover, .badge-dkshade[href]:focus {
    color: #fff;
    background-color: #0e1219;
}

.bg-dkshade {
    background-color: #212B3A !important;
}

a.bg-dkshade:hover, a.bg-dkshade:focus,
button.bg-dkshade:hover,
button.bg-dkshade:focus {
    background-color: #0e1219 !important;
}

.border-dkshade {
    border-color: #212B3A !important;
}

.btn-dkshade {
    color: #fff;
    background-color: #212B3A;
    border-color: #212B3A;
}

.btn-dkshade:hover {
    color: #fff;
    background-color: #141a23;
    border-color: #0e1219;
}

.btn-dkshade:focus, .btn-dkshade.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-dkshade.disabled, .btn-dkshade:disabled {
    color: #fff;
    background-color: #212B3A;
    border-color: #212B3A;
}

.btn-dkshade:not(:disabled):not(.disabled):active, .btn-dkshade:not(:disabled):not(.disabled).active, .show > .btn-dkshade.dropdown-toggle {
    color: #fff;
    background-color: #0e1219;
    border-color: #080b0f;
}

.btn-dkshade:not(:disabled):not(.disabled):active:focus, .btn-dkshade:not(:disabled):not(.disabled).active:focus, .show > .btn-dkshade.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-outline-dkshade {
    color: #212B3A;
    background-color: transparent;
    border-color: #212B3A;
}

.btn-outline-dkshade:hover {
    color: #fff;
    background-color: #212B3A;
    border-color: #212B3A;
}

.btn-outline-dkshade:focus, .btn-outline-dkshade.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-outline-dkshade.disabled, .btn-outline-dkshade:disabled {
    color: #212B3A;
    background-color: transparent;
}

.btn-outline-dkshade:not(:disabled):not(.disabled):active, .btn-outline-dkshade:not(:disabled):not(.disabled).active, .show > .btn-outline-dkshade.dropdown-toggle {
    color: #fff;
    background-color: #212B3A;
    border-color: #212B3A;
}

.btn-outline-dkshade:not(:disabled):not(.disabled):active:focus, .btn-outline-dkshade:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dkshade.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.list-group-item-dkshade {
    color: #000000;
    background-color: #6882a9;
}

.list-group-item-dkshade.list-group-item-action:hover, .list-group-item-dkshade.list-group-item-action:focus {
    color: #000000;
    background-color: #5a759e;
}

.list-group-item-dkshade.list-group-item-action.active {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
}

.table-dkshade,
.table-dkshade > th,
.table-dkshade > td {
    background-color: #6882a9;
}

.table-hover .table-dkshade:hover {
    background-color: #5a759e;
}

.table-hover .table-dkshade:hover > td,
.table-hover .table-dkshade:hover > th {
    background-color: #5a759e;
}

.text-dkshade {
    color: #212B3A !important;
}

a.text-dkshade:hover, a.text-dkshade:focus {
    color: #0e1219 !important;
}

