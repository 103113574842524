/*------------------------------------
lishade, liaccent, miuvi, dkaccent, dkshade
------------------------------------*/

/*------------------------------------
- COLOR lishade
------------------------------------*/
.alert-lishade {
    color: #9cbdc6;
    background-color: #16b14713d;
    border-color: #15e13e135;
}

.alert-lishade hr {
    border-top-color: #14e13412c;
}

.alert-lishade .alert-link {
    color: #7ba7b3;
}

.badge-lishade {
    color: #212529;
    background-color: #EAF1F3;
}

.badge-lishade[href]:hover, .badge-lishade[href]:focus {
    color: #212529;
    background-color: #c9dbe0;
}

.bg-lishade {
    background-color: #EAF1F3 !important;
}

a.bg-lishade:hover, a.bg-lishade:focus,
button.bg-lishade:hover,
button.bg-lishade:focus {
    background-color: #c9dbe0 !important;
}

.border-lishade {
    border-color: #EAF1F3 !important;
}

.btn-lishade {
    color: #212529;
    background-color: #EAF1F3;
    border-color: #EAF1F3;
}

.btn-lishade:hover {
    color: #212529;
    background-color: #d3e1e6;
    border-color: #c9dbe0;
}

.btn-lishade:focus, .btn-lishade.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 241, 243, 0.5);
}

.btn-lishade.disabled, .btn-lishade:disabled {
    color: #212529;
    background-color: #EAF1F3;
    border-color: #EAF1F3;
}

.btn-lishade:not(:disabled):not(.disabled):active, .btn-lishade:not(:disabled):not(.disabled).active, .show > .btn-lishade.dropdown-toggle {
    color: #212529;
    background-color: #c9dbe0;
    border-color: #bfd4da;
}

.btn-lishade:not(:disabled):not(.disabled):active:focus, .btn-lishade:not(:disabled):not(.disabled).active:focus, .show > .btn-lishade.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 241, 243, 0.5);
}

.btn-outline-lishade {
    color: #EAF1F3;
    background-color: transparent;
    border-color: #EAF1F3;
}

.btn-outline-lishade:hover {
    color: #212529;
    background-color: #EAF1F3;
    border-color: #EAF1F3;
}

.btn-outline-lishade:focus, .btn-outline-lishade.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 241, 243, 0.5);
}

.btn-outline-lishade.disabled, .btn-outline-lishade:disabled {
    color: #EAF1F3;
    background-color: transparent;
}

.btn-outline-lishade:not(:disabled):not(.disabled):active, .btn-outline-lishade:not(:disabled):not(.disabled).active, .show > .btn-outline-lishade.dropdown-toggle {
    color: #212529;
    background-color: #EAF1F3;
    border-color: #EAF1F3;
}

.btn-outline-lishade:not(:disabled):not(.disabled):active:focus, .btn-outline-lishade:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-lishade.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 241, 243, 0.5);
}

.list-group-item-lishade {
    color: #9cbdc6;
    background-color: #15e13e135;
}

.list-group-item-lishade.list-group-item-action:hover, .list-group-item-lishade.list-group-item-action:focus {
    color: #9cbdc6;
    background-color: #14e13412c;
}

.list-group-item-lishade.list-group-item-action.active {
    color: #212529;
    background-color: #9cbdc6;
    border-color: #9cbdc6;
}

.table-lishade,
.table-lishade > th,
.table-lishade > td {
    background-color: #15e13e135;
}

.table-hover .table-lishade:hover {
    background-color: #14e13412c;
}

.table-hover .table-lishade:hover > td,
.table-hover .table-lishade:hover > th {
    background-color: #14e13412c;
}

.text-lishade {
    color: #EAF1F3 !important;
}

a.text-lishade:hover, a.text-lishade:focus {
    color: #c9dbe0 !important;
}

/*------------------------------------
- COLOR liaccent
------------------------------------*/
.alert-liaccent {
    color: #4a5045;
    background-color: #eeefed;
    border-color: #e3e6e2;
}

.alert-liaccent hr {
    border-top-color: #d7dad4;
}

.alert-liaccent .alert-link {
    color: #31352e;
}

.badge-liaccent {
    color: #212529;
    background-color: #879180;
}

.badge-liaccent[href]:hover, .badge-liaccent[href]:focus {
    color: #212529;
    background-color: #6d7667;
}

.bg-liaccent {
    background-color: #879180 !important;
}

a.bg-liaccent:hover, a.bg-liaccent:focus,
button.bg-liaccent:hover,
button.bg-liaccent:focus {
    background-color: #6d7667 !important;
}

.border-liaccent {
    border-color: #879180 !important;
}

.btn-liaccent {
    color: #212529;
    background-color: #879180;
    border-color: #879180;
}

.btn-liaccent:hover {
    color: #212529;
    background-color: #757f6e;
    border-color: #6d7667;
}

.btn-liaccent:focus, .btn-liaccent.focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 145, 128, 0.5);
}

.btn-liaccent.disabled, .btn-liaccent:disabled {
    color: #212529;
    background-color: #879180;
    border-color: #879180;
}

.btn-liaccent:not(:disabled):not(.disabled):active, .btn-liaccent:not(:disabled):not(.disabled).active, .show > .btn-liaccent.dropdown-toggle {
    color: #212529;
    background-color: #6d7667;
    border-color: #666e5f;
}

.btn-liaccent:not(:disabled):not(.disabled):active:focus, .btn-liaccent:not(:disabled):not(.disabled).active:focus, .show > .btn-liaccent.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 145, 128, 0.5);
}

.btn-outline-liaccent {
    color: #879180;
    background-color: transparent;
    border-color: #879180;
}

.btn-outline-liaccent:hover {
    color: #212529;
    background-color: #879180;
    border-color: #879180;
}

.btn-outline-liaccent:focus, .btn-outline-liaccent.focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 145, 128, 0.5);
}

.btn-outline-liaccent.disabled, .btn-outline-liaccent:disabled {
    color: #879180;
    background-color: transparent;
}

.btn-outline-liaccent:not(:disabled):not(.disabled):active, .btn-outline-liaccent:not(:disabled):not(.disabled).active, .show > .btn-outline-liaccent.dropdown-toggle {
    color: #212529;
    background-color: #879180;
    border-color: #879180;
}

.btn-outline-liaccent:not(:disabled):not(.disabled):active:focus, .btn-outline-liaccent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-liaccent.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 145, 128, 0.5);
}

.list-group-item-liaccent {
    color: #4a5045;
    background-color: #e3e6e2;
}

.list-group-item-liaccent.list-group-item-action:hover, .list-group-item-liaccent.list-group-item-action:focus {
    color: #4a5045;
    background-color: #d7dad4;
}

.list-group-item-liaccent.list-group-item-action.active {
    color: #212529;
    background-color: #4a5045;
    border-color: #4a5045;
}

.table-liaccent,
.table-liaccent > th,
.table-liaccent > td {
    background-color: #e3e6e2;
}

.table-hover .table-liaccent:hover {
    background-color: #d7dad4;
}

.table-hover .table-liaccent:hover > td,
.table-hover .table-liaccent:hover > th {
    background-color: #d7dad4;
}

.text-liaccent {
    color: #879180 !important;
}

a.text-liaccent:hover, a.text-liaccent:focus {
    color: #6d7667 !important;
}

/*------------------------------------
- COLOR miuvi
------------------------------------*/
.alert-miuvi {
    color: #1a605a;
    background-color: #cef2ee;
    border-color: #beede9;
}

.alert-miuvi hr {
    border-top-color: #aae8e2;
}

.alert-miuvi .alert-link {
    color: #0f3834;
}

.badge-miuvi {
    color: #212529;
    background-color: #34C1B4;
}

.badge-miuvi[href]:hover, .badge-miuvi[href]:focus {
    color: #212529;
    background-color: #29988e;
}

.bg-miuvi {
    background-color: #34C1B4 !important;
}

a.bg-miuvi:hover, a.bg-miuvi:focus,
button.bg-miuvi:hover,
button.bg-miuvi:focus {
    background-color: #29988e !important;
}

.border-miuvi {
    border-color: #34C1B4 !important;
}

.btn-miuvi {
    color: #212529;
    background-color: #34C1B4;
    border-color: #34C1B4;
}

.btn-miuvi:hover {
    color: #212529;
    background-color: #2ca499;
    border-color: #29988e;
}

.btn-miuvi:focus, .btn-miuvi.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 193, 180, 0.5);
}

.btn-miuvi.disabled, .btn-miuvi:disabled {
    color: #212529;
    background-color: #34C1B4;
    border-color: #34C1B4;
}

.btn-miuvi:not(:disabled):not(.disabled):active, .btn-miuvi:not(:disabled):not(.disabled).active, .show > .btn-miuvi.dropdown-toggle {
    color: #212529;
    background-color: #29988e;
    border-color: #258c83;
}

.btn-miuvi:not(:disabled):not(.disabled):active:focus, .btn-miuvi:not(:disabled):not(.disabled).active:focus, .show > .btn-miuvi.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 193, 180, 0.5);
}

.btn-outline-miuvi {
    color: #34C1B4;
    background-color: transparent;
    border-color: #34C1B4;
}

.btn-outline-miuvi:hover {
    color: #212529;
    background-color: #34C1B4;
    border-color: #34C1B4;
}

.btn-outline-miuvi:focus, .btn-outline-miuvi.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 193, 180, 0.5);
}

.btn-outline-miuvi.disabled, .btn-outline-miuvi:disabled {
    color: #34C1B4;
    background-color: transparent;
}

.btn-outline-miuvi:not(:disabled):not(.disabled):active, .btn-outline-miuvi:not(:disabled):not(.disabled).active, .show > .btn-outline-miuvi.dropdown-toggle {
    color: #212529;
    background-color: #34C1B4;
    border-color: #34C1B4;
}

.btn-outline-miuvi:not(:disabled):not(.disabled):active:focus, .btn-outline-miuvi:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-miuvi.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 193, 180, 0.5);
}

.list-group-item-miuvi {
    color: #1a605a;
    background-color: #beede9;
}

.list-group-item-miuvi.list-group-item-action:hover, .list-group-item-miuvi.list-group-item-action:focus {
    color: #1a605a;
    background-color: #aae8e2;
}

.list-group-item-miuvi.list-group-item-action.active {
    color: #212529;
    background-color: #1a605a;
    border-color: #1a605a;
}

.table-miuvi,
.table-miuvi > th,
.table-miuvi > td {
    background-color: #beede9;
}

.table-hover .table-miuvi:hover {
    background-color: #aae8e2;
}

.table-hover .table-miuvi:hover > td,
.table-hover .table-miuvi:hover > th {
    background-color: #aae8e2;
}

.text-miuvi {
    color: #34C1B4 !important;
}

a.text-miuvi:hover, a.text-miuvi:focus {
    color: #29988e !important;
}

/*------------------------------------
- COLOR dkaccent
------------------------------------*/
.alert-dkaccent {
    color: #353230;
    background-color: #d8d5d3;
    border-color: #cecbc9;
}

.alert-dkaccent hr {
    border-top-color: #c2bebb;
}

.alert-dkaccent .alert-link {
    color: #1a1917;
}

.badge-dkaccent {
    color: #fff;
    background-color: #766F6A;
}

.badge-dkaccent[href]:hover, .badge-dkaccent[href]:focus {
    color: #fff;
    background-color: #5b5551;
}

.bg-dkaccent {
    background-color: #766F6A !important;
}

a.bg-dkaccent:hover, a.bg-dkaccent:focus,
button.bg-dkaccent:hover,
button.bg-dkaccent:focus {
    background-color: #5b5551 !important;
}

.border-dkaccent {
    border-color: #766F6A !important;
}

.btn-dkaccent {
    color: #fff;
    background-color: #766F6A;
    border-color: #766F6A;
}

.btn-dkaccent:hover {
    color: #fff;
    background-color: #635d59;
    border-color: #5b5551;
}

.btn-dkaccent:focus, .btn-dkaccent.focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 111, 106, 0.5);
}

.btn-dkaccent.disabled, .btn-dkaccent:disabled {
    color: #fff;
    background-color: #766F6A;
    border-color: #766F6A;
}

.btn-dkaccent:not(:disabled):not(.disabled):active, .btn-dkaccent:not(:disabled):not(.disabled).active, .show > .btn-dkaccent.dropdown-toggle {
    color: #fff;
    background-color: #5b5551;
    border-color: #534e4a;
}

.btn-dkaccent:not(:disabled):not(.disabled):active:focus, .btn-dkaccent:not(:disabled):not(.disabled).active:focus, .show > .btn-dkaccent.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 111, 106, 0.5);
}

.btn-outline-dkaccent {
    color: #766F6A;
    background-color: transparent;
    border-color: #766F6A;
}

.btn-outline-dkaccent:hover {
    color: #fff;
    background-color: #766F6A;
    border-color: #766F6A;
}

.btn-outline-dkaccent:focus, .btn-outline-dkaccent.focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 111, 106, 0.5);
}

.btn-outline-dkaccent.disabled, .btn-outline-dkaccent:disabled {
    color: #766F6A;
    background-color: transparent;
}

.btn-outline-dkaccent:not(:disabled):not(.disabled):active, .btn-outline-dkaccent:not(:disabled):not(.disabled).active, .show > .btn-outline-dkaccent.dropdown-toggle {
    color: #fff;
    background-color: #766F6A;
    border-color: #766F6A;
}

.btn-outline-dkaccent:not(:disabled):not(.disabled):active:focus, .btn-outline-dkaccent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dkaccent.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 111, 106, 0.5);
}

.list-group-item-dkaccent {
    color: #353230;
    background-color: #cecbc9;
}

.list-group-item-dkaccent.list-group-item-action:hover, .list-group-item-dkaccent.list-group-item-action:focus {
    color: #353230;
    background-color: #c2bebb;
}

.list-group-item-dkaccent.list-group-item-action.active {
    color: #fff;
    background-color: #353230;
    border-color: #353230;
}

.table-dkaccent,
.table-dkaccent > th,
.table-dkaccent > td {
    background-color: #cecbc9;
}

.table-hover .table-dkaccent:hover {
    background-color: #c2bebb;
}

.table-hover .table-dkaccent:hover > td,
.table-hover .table-dkaccent:hover > th {
    background-color: #c2bebb;
}

.text-dkaccent {
    color: #766F6A !important;
}

a.text-dkaccent:hover, a.text-dkaccent:focus {
    color: #5b5551 !important;
}

/*------------------------------------
- COLOR dkshade
------------------------------------*/
.alert-dkshade {
    color: #000000;
    background-color: #758db1;
    border-color: #6882a9;
}

.alert-dkshade hr {
    border-top-color: #5a759e;
}

.alert-dkshade .alert-link {
    color: #000000;
}

.badge-dkshade {
    color: #fff;
    background-color: #212B3A;
}

.badge-dkshade[href]:hover, .badge-dkshade[href]:focus {
    color: #fff;
    background-color: #0e1219;
}

.bg-dkshade {
    background-color: #212B3A !important;
}

a.bg-dkshade:hover, a.bg-dkshade:focus,
button.bg-dkshade:hover,
button.bg-dkshade:focus {
    background-color: #0e1219 !important;
}

.border-dkshade {
    border-color: #212B3A !important;
}

.btn-dkshade {
    color: #fff;
    background-color: #212B3A;
    border-color: #212B3A;
}

.btn-dkshade:hover {
    color: #fff;
    background-color: #141a23;
    border-color: #0e1219;
}

.btn-dkshade:focus, .btn-dkshade.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-dkshade.disabled, .btn-dkshade:disabled {
    color: #fff;
    background-color: #212B3A;
    border-color: #212B3A;
}

.btn-dkshade:not(:disabled):not(.disabled):active, .btn-dkshade:not(:disabled):not(.disabled).active, .show > .btn-dkshade.dropdown-toggle {
    color: #fff;
    background-color: #0e1219;
    border-color: #080b0f;
}

.btn-dkshade:not(:disabled):not(.disabled):active:focus, .btn-dkshade:not(:disabled):not(.disabled).active:focus, .show > .btn-dkshade.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-outline-dkshade {
    color: #212B3A;
    background-color: transparent;
    border-color: #212B3A;
}

.btn-outline-dkshade:hover {
    color: #fff;
    background-color: #212B3A;
    border-color: #212B3A;
}

.btn-outline-dkshade:focus, .btn-outline-dkshade.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.btn-outline-dkshade.disabled, .btn-outline-dkshade:disabled {
    color: #212B3A;
    background-color: transparent;
}

.btn-outline-dkshade:not(:disabled):not(.disabled):active, .btn-outline-dkshade:not(:disabled):not(.disabled).active, .show > .btn-outline-dkshade.dropdown-toggle {
    color: #fff;
    background-color: #212B3A;
    border-color: #212B3A;
}

.btn-outline-dkshade:not(:disabled):not(.disabled):active:focus, .btn-outline-dkshade:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dkshade.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 43, 58, 0.5);
}

.list-group-item-dkshade {
    color: #000000;
    background-color: #6882a9;
}

.list-group-item-dkshade.list-group-item-action:hover, .list-group-item-dkshade.list-group-item-action:focus {
    color: #000000;
    background-color: #5a759e;
}

.list-group-item-dkshade.list-group-item-action.active {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
}

.table-dkshade,
.table-dkshade > th,
.table-dkshade > td {
    background-color: #6882a9;
}

.table-hover .table-dkshade:hover {
    background-color: #5a759e;
}

.table-hover .table-dkshade:hover > td,
.table-hover .table-dkshade:hover > th {
    background-color: #5a759e;
}

.text-dkshade {
    color: #212B3A !important;
}

a.text-dkshade:hover, a.text-dkshade:focus {
    color: #0e1219 !important;
}
