/*
  #F0F5F1
  #92A87F
  #34C1B4
  #85726B
  #233244

  EAF1F3 - lishade
*//*
  #c3efeb lishade
  #879180 liaccent
  #34C1B4 main
  #766F6A dkaccent
  #212B3A dkshade
  #33c5b6 primary
  #4faba0 secondary
  #212b3a info
  #45b66f success
  #c2a637 warning
  #f44336 danger
*//*
  #F2F4EF
  #809868
  #34C1B4
  #8A556E
  #396169
  #46b46f
  #c3a337
  #f44336
*//*
  #EBE7E1
  #76BFCA
  #278F85
  #838C5C
  #1F2928
*/
/*
*/

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  z-index:2;
  color:#ffffff;
  background-color:#33c5b6;
  border-color:#33c5b6;
}

/* Flos Farbenpracht */
.text-blue {
  color: #050038;
}
.text-lightmiuvi {
  color: #8BDDD4;
}
.text-pink {
  color: #ff7bac;
}
.text-yellow {
  color: #FFD02F;
}
.text-lightyellow {
  color: #FFEAA8;
}
.text-lightpink {
  color: #FF9DC1;
}
.bg-yellow {
  background-color: #FFD02F;
}
.bg-lightpink {
  background-color: #FF9DC1;
}
.bg-lightmiuvi {
  background-color: #8BDDD4;
}

.bg-lightyellow {
  background-color: #FFEAA8;
}
.bg-blue {
  background-color: #050038;
}
.bg-pink {
  background-color: #ff7bac;
}
.border-yellow {
  border-color: #FFD02F;
}
.border-pink {
  border-color: #ff7bac;
}
.border-blue {
  border-color: #050038;
}
/* aus */


.text-coral {
  color: #f8ff8b;
}

.text-primary.hovered {
  color: #4faba0;
}

.bg-orange {
  background-color: #f18837;
}

.bg-purple {
  background-color: #7533b5;
}

.border-orange {
  border-color: #f18837;
}

.border-purple {
  border-color: #7533b5;
}

.bg-red {
  background-color: #F22654;
}

.text-red {
  color: #F22654;
}

.border-red {
  border-color: #F22654;
}

.border-yellow {
  border-color: #ffd60b;
}


.hoverable:hover {
  border-color: #4faba0;
}

/* TODO change in bootstrap override for -lishade-, change in app.js */
.bg-col-mi {
  background-color: #c3efeb;
}

.border-dashed {
  border-style: dashed;
  border-width: medium;
}

.form-control:focus {
  border-color: #766F6A;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(118, 111, 106, 0.6);
}

.rounded-xxl {
  border-radius: 2rem;
}

/* TODO make colors changeable */
.border-corners {position:relative;}
.border-corners:before, .border-corners:after, .border-corners>:first-child:before, .border-corners>:first-child:after {
    position:absolute;
    width:5rem; height: 5rem;
    border-color:#33c5b6;
    border-style:solid;
    content: ' ';
}
.border-corners:before {top:0;left:0;border-width: 5px 0 0 5px}
.border-corners:after {top:0;right:0;border-width: 5px 5px 0 0}
.border-corners>:first-child:before {bottom:0;right:0;border-width: 0 5px 5px 0}
.border-corners>:first-child:after {bottom:0;left:0;border-width: 0 0 5px 5px}

.border-corners-s {position:relative; width:10rem; height: 7.5rem;}
.border-corners-s:before, .border-corners-s:after, .border-corners-s>:first-child:before, .border-corners-s>:first-child:after {
    position:absolute;
    width:1.5rem; height: 1.5rem;
    border-color:#33c5b6;
    border-style:solid;
    content: ' ';
}
.border-corners-s:before {top:0;left:0;border-width: 1px 0 0 1px}
.border-corners-s:after {top:0;right:0;border-width: 1px 1px 0 0}
.border-corners-s>:first-child:before {bottom:0;right:0;border-width: 0 1px 1px 0}
.border-corners-s>:first-child:after {bottom:0;left:0;border-width: 0 0 1px 1px}

@keyframes blink {
  50% {
    opacity: 0.0;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0.0;
  }
}
.blink {
  animation: blink 2s step-start 0s infinite;
  -webkit-animation: blink 2s step-start 0s infinite;
}


.embed-responsive-9by16 {
  padding-bottom: 177.78%;
}


@media (max-width: 575.8px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  h5 {
    font-size: 1rem;
  }
}

/* Number of Cards by Row based on Viewport */
@media (min-width: 576px) {
  .card-deck .card.card-wider {
    min-width: 50.1%; /* 1 Column */
    max-width: 80%;
  }
  .card-deck .card {
      /* min-width: 50.1%; /* 1 Column *//*
      max-width: 80%;
      margin-bottom: 12px; */
      min-width: 33.4%;  /* 2 Columns */
      max-width: 50%;
  }
}
@media (min-width: 768px) {
  .card-deck .card.card-wider {
    min-width: 33.4%;  /* 2 Columns */
    max-width: 50%;
  }
  .card-deck .card {
      /* min-width: 33.4%;  2 Columns
      max-width: 50%; */
      min-width: 25.1%;  /* 3 Columns */
      max-width: 33.3%;
  }
}
@media (min-width: 992px) {
  .card-deck .card {
      min-width: 25.1%;  /* 3 Columns */
      max-width: 33.3%;
  }
}
@media (min-width: 1200px) {
  .card-deck .card.card-wider {
    min-width: 0;
    max-width: 100%;
  }
  .card-deck .card {
      min-width: 0;
      max-width: 100%;
  }
}


/* add bg-video class to container containing video tag */
/* put everything else into child container of parent container */
.bg-video {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.bg-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.bg-video .container {
  position: relative;
  z-index: 2;
}
@media (pointer: coarse) and (hover: none) {
  /* .bg-video video{
    background: url("/businessman_fb.png") black no-repeat center center scroll;
  } */
  .bg-video video{
    display: none;
  }
}


.dnd {
  height: 10rem;
}

.dnd-b Input[type=file] { /*TODO get to work*/
    display: none;
  }

.colorPicker {
  height: 10rem;
  width: 10rem;
}
.colorPicker input[type=color] { /*broken*/
  display: none;
}

#modalCountdown h1{
  color: white;
  font-size: 185px;
  font-family: 'Montserrat', sans-serif;
}

#countdown-text{
  position:relative;
    color: rgb(230, 200, 98);
    z-index:1;
    top: 0;
    text-align: center; 
}

.noUi-horizontal .noUi-handle {
}

.noUi-handle[data-handle="0"] {
  height: 26px !important;
  top: -5px !important;
  background-color: #212B3A;
  z-index: 5; /*why not working?*/
  border-radius: 10px 0 0 10px;
  width: 10px !important;
  right: 0 !important;
}

.noUi-handle[data-handle="1"] {
  height: 26px !important;
  top: -5px !important;
  background-color: #fff;
  z-index: -5;
  border-radius:  10px 10px 10px 10px;
  width: 4px !important;
  right: -2px !important;
}

.noUi-handle[data-handle="2"] {
  height: 26px !important;
  top: -5px !important;
  background-color: #212B3A;
  z-index: 5; /*why not working?*/
  border-radius: 0 10px 10px 0;
  width: 10px !important;
  right: -10px !important;
}

.noUi-handle:before, .noUi-handle:after {
  display: none !important;
} 

#c-p-bn, #s-all-bn.c-bn {
  background-color: #33c5b6 !important;
}

/* .theme_turquoise{
  --cc-bg: #161a1c;
  --cc-text: #d8e5ea;
  --cc-btn-primary-bg: #60fed2;
  --cc-btn-primary-text: #000;
  --cc-btn-primary-hover-bg: #4dd4ae;
  --cc-btn-secondary-bg: #242c31;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #2b353c;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #343e45;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);
  --cc-cookie-category-block-bg: #1e2428;
  --cc-cookie-category-block-bg-hover: #242c31;
  --cc-section-border: #222a30;
  --cc-block-text: #bac9cf;
  --cc-cookie-table-border: #2b3035;
  --cc-overlay-bg: rgba(4, 6, 8, .85);
  --cc-webkit-scrollbar-bg: #2c343a;
  --cc-webkit-scrollbar-bg-hover: #384148;
}

.theme_turquoise .cc_div a{
  color: var(--cc-btn-primary-bg);
}

.theme_turquoise #cc_div #s-hdr{
  border-bottom: none;
}

.theme_turquoise .cc_div #c-txt{
  color: #b0b8c6!important;
} */
